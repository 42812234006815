.backbg{
  background-color: black;
}
.imageParent{
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: black;
}
.image{
  object-fit: cover;
}